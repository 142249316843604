import { views } from "@xbs/filemanager";

// copy of constants from fm
const FOLDER = 0x01;
const MANY = 0x02;
const TEXT = 0x04;
const VIEW = 0x08;
const FULL = 0x10;
const TREE = 0x20;
const SEARCH = 0x40;
const EMPTY = 0x80;

const STAR = 0x100;
const TRASH = 0x7f; // 1111111 (!sic) this was done to make these options invisible for that '1-bit' filtering

export default class MenuBodyView extends views["menus/menubody"] {
	constructor(app, config) {
		super(app, config);
		this.state = config.state;
	}
	config() {
		const _ = this.app.getService("locale")._;
		const menu = super.config();

		const options = [
			{
				id: "favorite",
				show: FOLDER | TREE | STAR,
				value: _("Add to favorites"),
				icon: "dmi-star-outline",
			},
			{
				id: "unfavorite",
				show: FOLDER | TREE | STAR,
				value: _("Remove from favorites"),
				icon: "dmi-star",
			},
			{
				id: "share",
				value: _("Share"),
				show: TREE,
				icon: "dmi-share",
			},
			{
				$template: "Separator",
				show: TREE,
			},
		];

		menu.data.splice(11, 0, ...options);

		menu.data = menu.data.concat([
			{
				id: "restore",
				value: _("Restore"),
				show: TRASH,
				icon: "dmi-delete-restore",
			},
			{
				id: "remove",
				value: _("Delete from trash"),
				show: TRASH,
				icon: "dmi-delete-empty",
				hotkey: "Del / &#8592;",
			},
		]);

		menu.template = obj => {
			return (
				(obj.icon
					? `<span class="webix_list_icon webix_icon ${obj.icon}"></span>`
					: "") +
				obj.value +
				(obj.hotkey
					? `<span class="webix_fmanager_context-menu-hotkey">${obj.hotkey}</span>`
					: "")
			);
		};

		return menu;
	}
	FilterOptions(files) {
		const source = this.state ? this.state.source : null;
		const menu = this.getRoot();
		this.Files = files;

		if (source !== "trash") {
			// here we fully copy and slightly change super.FilterOptions
			const file = files[0];
			const many = files.length > 1;

			const vtypes = ["image", "audio", "video", "code", "pdf"];
			const viewable = vtypes.find(t => t === file.type || t === file.$ext);
			const editable =
				file.type === "code" || (file.$ext === "xls" || file.$ext === "xlsx");
			const search = this.app.getState().search;
			const empty = file.type === "empty";

			this.getRoot().define("width", search && !many ? 270 : 200);

			this.getRoot().filter(o => {
				return !(
					(!(o.show & EMPTY) && empty) ||
					(many && !(o.show & MANY)) ||
					(file.type === "folder" && !(o.show & FOLDER)) ||
					(o.show & TEXT && !editable) ||
					(o.show & VIEW && !viewable) ||
					(o.show & FULL && !this.compact) ||
					(o.show & TREE && this.tree) ||
					(o.show & SEARCH && !search) ||
					(o.show & STAR && file.star
						? o.id === "favorite"
						: o.id === "unfavorite")
				);
			});

			// filter out copy-cut-paste section for group folders
			this.FilterCopyPaste(source, menu);
		} else {
			menu.define("width", 250);
			menu.filter(o => o.id === "remove" || o.id === "restore");
		}
	}
	FilterCopyPaste(source, menu) {
		if (source !== "files") {
			menu.filter(
				o => {
					return (
						o.show ^ (FOLDER | MANY | TREE) && o.show ^ (FOLDER | TREE | EMPTY)
					);
				},
				null,
				true
			);
		}
	}
}
